import React,{ Component } from 'react';

class SignUpHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_email : "",
        access_token : ""
    }
  }
  callbackFunction = (value1,value2) => {
    this.setState({
      user_email: value1,
      access_token : value2
    },()=>{
      this.sendDataApp(this.state.user_email,this.state.access_token);
    })
}

sendDataApp = (value_email,value_accesstoken) => {
  this.props.parentCallbackRoot(value_email,value_accesstoken);
}
  render() {
    console.log("email",this.state.user_email);
    console.log("access_token",this.state.access_token);
    return (
      <header className="section-header">
<section className="header-main shadow-sm bg-white">
<div className="container">
  <div className="row align-items-center pt-3 pb-3">
    <div className="col-12">
      <a
        href="/"
        className="brand-wrap mb-0"
      >
        <img
          alt="#"
          className="img-fluid"
          src="img/single-rest/dt-logo.png"
        />
      </a>
    </div>
  </div>
</div>
</section>
</header>

        );
  }
}


export default SignUpHeader;
