import React,{useEffect,useMemo,useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { fetchMerchantToken } from './Redux/MerchantToken/MerchantTokenActions';
import { fetchConfig } from './Redux/Config/ConfigActions';
//import './App.css';
import Header from './components/Header'
import AllRestaurants from './components/AllRestaurants'
import BelowHeader from './components/BelowHeader'
import BannerNew from './components/BannerNew'
import Menu3 from './components/Menu3'
import BookTable from './components/BookTable'
import Footer from './components/Footer'
import FeatherIcon from 'feather-icons-react';
import { useGeolocated } from "react-geolocated";
import Autocomplete from "react-google-autocomplete";
import { useHistory } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from "react-places-autocomplete";


function Home() {
  // store data access start
const merchant_data = useSelector(state =>state.MerchantToken)
const config_data = useSelector(state =>state.Config)


// store data access End
  const dispatch = useDispatch()  // for accessing the redux function
  

  // component all states define start
  const [merchantInfo,setMerchantInfo] = useState([])
  const [configInfo,setConfigInfo] = useState([])
  const [detailed_cart_item,setDetailed_cart_item] = useState([])
  const [banner_info,setBanner_info] = useState([])
  const [placeId,setPlaceId] = useState(null)
  const [placeDetails,setPlaceDetails] = useState(null)
  const [current_selected_latlng, setCurrent_selected_latlng] = useState(null)
  // component all states define End

   //hooks start
   // useEffect(() =>{
   //   dispatch(fetchMerchantToken())
   // },[dispatch])

   useEffect(() => {
     // fetch place details for the first element in placePredictions array
     if (placeId != null) {
       geocodeByPlaceId(placeId)
         .then(results => getLatLng(results[0]))
         .then(result => setCurrent_selected_latlng(result));
     }
     console.log('placeid', placeId);
   }, [placeId]);

   useEffect(() => {
   if (current_selected_latlng && current_selected_latlng.lat != null) {
     window.localStorage.setItem("current_selected_lat", current_selected_latlng.lat);
     window.localStorage.setItem("current_selected_lng", current_selected_latlng.lng);
   }
 }, [current_selected_latlng]);



   useMemo(()=>{
      setMerchantInfo(merchant_data.merchant_token.object)
  },[merchant_data && merchant_data.merchant_token && merchant_data.merchant_token.object])

  // useEffect(() =>{
  //   if(merchantInfo && merchantInfo.access_token){
  //     const user_token = merchantInfo.access_token
  //     dispatch(fetchConfig(user_token))
  //   }
  //
  // },[merchantInfo && dispatch])

  useMemo(()=>{
   if(config_data && config_data.config && config_data.config.object){
     setConfigInfo(config_data.config.object)
   }
 },[config_data])
   //hooks end

const detailed_cart_item_callbackFunction = (childdata) =>{
  setDetailed_cart_item(childdata)
}
const banner_info_callbackFunction = (childdata) =>{
  setBanner_info(childdata)
}

const current_selected_address = localStorage.getItem("current_formatted_address") != null ? localStorage.getItem("current_formatted_address") : '';




  return (
    <div className="App home-new">
      <Header configInfo={configInfo} Detailed_cart_item={detailed_cart_item}/>

        <section className="home_search_section home-page-2">
             <div className="container-fluid p-0">
               <div className="row bg-warning">
                 <div className="col-md-8 bg-warning">
                   <div className="h_search_form_col">
                     <div className="row">
                       <div className="col-md-12" id="home_searchbar">
                         <form id="homeform">
                           <div className="row">
                             <div className="col-md-9">
                               <div className="field-group">
                               <FeatherIcon icon="search"/>
                               {/*<input type="text" className="form-control src" placeholder="Enter Name, City, or ZIP Code" value={coords && coords.latitude ? `${coords.latitude}, ${coords.longitude}` : ''}/>*/}
                               <Autocomplete
                                 placeholder={current_selected_address}
                                 className="form-control src"
                             onPlaceSelected={(place) => {
                               //console.log(place);
                               setPlaceId(place.place_id)
                               window.localStorage.setItem("current_formatted_address", place.formatted_address);
                             }}
                           />
                             </div>
                           </div>
                           <div className="col-md-3">
                             <button className="btn loc location"><FeatherIcon icon="map-pin"/> Use Location</button>
                           </div>
                         </div>
                       </form>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="col-md-4 pl-0">
                 <img className="img1" src="/img/211006114703-best-meal-delivery-service-freshly%201.png" />
               </div>
             </div>
           </div>
         </section>
         <div className="container">
         <AllRestaurants />

         </div>

      {merchantInfo ?

      <Menu3 configInfo={configInfo}
      merchantInfo={merchantInfo}
      detailed_cart_item_parentcallbackfunction = {detailed_cart_item_callbackFunction}
      banner_info_parentcallbackfunction = {banner_info_callbackFunction}
      />
     :(<div className="row">
     <div className="col-12">
       <div className="menuLoader hm front">
         <img src="/img/menu-item-loader.gif"/>
         <img src="/img/menu-item-loader.gif"/>
           <img src="/img/menu-item-loader.gif"/>
             <img src="/img/menu-item-loader.gif"/>
       </div>
       </div>
      </div>
     )}


      <Footer configInfo={configInfo} merchantInfo={merchantInfo} banner_info={banner_info}/>
    </div>
  );
}

export default Home;
