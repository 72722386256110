import React, { useEffect, useMemo, useState } from 'react'
import { config } from '../config';
import { Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpecialLists } from '../Redux/RestaurantInformation/RestaurantInformationActions';

export default function Specials() {
  const dispatch = useDispatch();
  const [allEvents,setAllEvents] = useState([]);
  console.log(allEvents);
  const [configInfo,setConfigInfo] = useState([]);
  const [merchantInfo,setMerchantInfo] = useState([])
  console.log(configInfo);
  const allEventsData = useSelector(state =>state.RestaurantInformation);
  console.log(allEventsData);
  
  const merchant_data = useSelector(state =>state.MerchantToken)
  console.log(merchant_data);
  const config_data = useSelector(state =>state.Config)
  console.log(config_data);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleModalOpen = (item) => {
    setSelectedImage(item);
  };

  console.log(selectedImage);
  const handleModalClose = () => {
    setSelectedImage(null);
  };


  useMemo(()=>{
    if(config_data && config_data.config && config_data.config.object){
      setConfigInfo(config_data.config.object.MERCHANT_ID)
    }
  },[config_data])

  useMemo(()=>{
    setMerchantInfo(merchant_data.merchant_token.object.access_token)
},[merchant_data && merchant_data.merchant_token && merchant_data.merchant_token.object.access_token])
  
console.log(merchantInfo);
localStorage.setItem('merchantToken', merchantInfo);

useEffect(() =>{
if(configInfo){
  dispatch(fetchSpecialLists(configInfo))
 
}
}, [configInfo])

useMemo(() =>{
    if(allEventsData && allEventsData.eventlist && allEventsData.eventlist.data && allEventsData.eventlist.data.length > 0){
        setAllEvents(allEventsData.eventlist.data)
    }
  },[allEventsData])

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked); 
  };

  return (
    <div className="py-3 title d-flex align-items-center">
  
  <div className="container"> 
<div className="row"> 
{allEvents.length === 0 ? (
<div>No special events found </div>
) : (
  <>
  {allEvents.map((item,index) => {
function formatDate(dateTimeString) {
  const dateObj = new Date(dateTimeString);
  const month = dateObj.toLocaleString('default', { month: 'short' });
  const date = dateObj.getDate();
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Format the time with minutes but without seconds
  const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;

  return `${month} ${date}, ${year} ${formattedTime}`;
}
    return (  
      <div className="col-md-6 col-sm-6" key={item.id}> 
      <div className="card"> 
        <div className="card-body">
          <img
            className="card-img-top"
            src={item.display}
            alt="Card image cap"
            style={{ borderRadius: "10px" }}
            height={200}
            data-toggle="modal"
            data-target="#exampleModal1"
            onClick={() => handleModalOpen(item)}
          />
          <br/><br/>
          <h3 className="card-title text-center">
            <Link to="#" style={{ color: "#042954" }}>{item.name}</Link>
          </h3> 
          <div className="card-text text-center" style={{ fontSize: "14px" }}>
            {formatDate(item.start_time)} - {formatDate(item.end_time)}
          </div>
        </div>
      </div>

      <div className="modal" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
        <div className="modal-dialog" style={{ maxWidth: "1000px" }}>
          <div className="modal-content" style={{ margin: "20px 0 20px 0" }}>
            <div className="modal-body">
              {selectedImage && (
                <>
                <img
                  className="card-img-top"
                  src={selectedImage.image}
                  alt="Selected Image"
                  style={{ borderRadius: "10px" }}
                />
                <h3 className="text-left px-3 pt-3">
                <Link to="#" style={{ color: "#042954" }}>{selectedImage.name}</Link>
              </h3>
              <div className="text-left px-3 pb-3">{selectedImage.description}</div>
              </>
              )}
             
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleModalClose}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
    })}
    </>
)}

</div>
</div>
</div>
  )
}


