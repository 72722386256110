import React,{useEffect,useMemo,useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import {config} from '../config';
import { fetchRestaurantTiming } from '../Redux/RestaurantTiming/RestaurantTimingActions';
function Footer(props){
  // store data access start
const restaurantTiming_data = useSelector(state =>state.RestaurantTiming)
  const restaurantInformation_data = useSelector(state =>state.RestaurantInformation)
// store data access End
  const dispatch = useDispatch()  // for accessing the redux function

  // component all states define start
  const [restaurantTimingInfo,setRestaurantTimingInfo] = useState([])
  // component all states define End

   //hooks start
   useEffect(() =>{
     if(props && props.merchantInfo && props.merchantInfo.access_token){
       const user_token = props.merchantInfo.access_token
       dispatch(fetchRestaurantTiming(user_token))
     }
   },[dispatch && props && props.merchantInfo])

   useMemo(()=>{
    if(restaurantTiming_data && restaurantTiming_data.restaurant_timing && restaurantTiming_data.restaurant_timing.data){
      setRestaurantTimingInfo(restaurantTiming_data.restaurant_timing.data)
    }
  },[restaurantTiming_data])
   //hooks end
  return(
    <>
      <footer className="footer">

            {/* <!-- /.container --> */}
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 copyright-text">
                            © 2023  |  <a href="/privacy-policy">Privacy Policy</a> |  <a href="/terms-of-use">Terms of Use</a>  Powered by <span className="poweredby"> <a href="https://www.diningtek.com/">DiningTek</a></span>
                        </div>
                        <div className="col-md-6 footer-social">

                        </div>
                    </div>
                </div>
            </div>

      </footer>
    </>
  )
}

export default Footer
