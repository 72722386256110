import React,{ Component } from 'react';
import Header from '../components/Header';
import StripeScriptLoader from "react-stripe-script-loader";
import {
  StripeProvider,
  Elements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from "react-stripe-elements";
import MyStoreCheckout2 from '../components/MyStoreCheckout2';
import CardSection from '../components/CardSection';
import {config} from '../config';
//import Script from 'next/script';

class Second extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_email : "",
        access_token : "",
        current_tab:0,
        business_name: null,
        business_website : null,
        phonenumber : null,
        owner_name : null,
        business_address : null,
        business_tagline : null,
        city : null,
        zipcode : null,
        state : null,
        country : null,
        about : null,
        temp:null,
        incomplete_error:false,
        phone_error:false,
        zipcode_error:false,
        checkbox_error:false,
        authorized:false,
        salesCheck:false,
        zip_space_error:false,
        outletEndpoint_error:false,
        phone_space_error:false,
        card_error:false,
        card_error_message:'',
        outletEndpoint:'',
        primary_food_categories:[],
        secondary_food_categories:[],
        login_error:false,
        error_message:null,

    }
    this.OnTabChange = this.OnTabChange.bind(this);
  }
  callbackFunction = (value1,value2) => {
    this.setState({
      user_email: value1,
      access_token : value2
    },()=>{
      this.sendDataApp(this.state.user_email,this.state.access_token);
    })
}

OnTabChange = (tab) => {
  const regexp = /^\d+$/;
  const urlRegexp = new RegExp(/^(http|https):\/\/[^ "]+$/);
  this.setState({incomplete_error:false});
  if(this.state.business_name === null ||
    this.state.business_website === null ||
    this.state.phonenumber === null ||
    this.state.owner_name === null ||
    this.state.business_address === null ||
    this.state.business_tagline === null ||
    this.state.city === null ||
    this.state.zipcode === null ||
    this.state.state === null ||
    this.state.country === null ||
    this.state.business_name === "" ||
      this.state.business_website === "" ||
      this.state.phonenumber === "" ||
      this.state.owner_name === "" ||
      this.state.business_address === "" ||
      this.state.business_tagline === "" ||
      this.state.city === "" ||
      this.state.zipcode === "" ||
      this.state.state === "" ||
      this.state.country === ""
  ) {
    this.setState({outletEndpoint_error:false,incomplete_error:true,
    phone_error:false,
      zip_space_error:false,
      phone_space_error:false,
    zipcode_error:false});

  }
  else if(this.state.phonenumber != null && !regexp.test(this.state.phonenumber)) {
    this.setState({outletEndpoint_error:false,incomplete_error:false,
    phone_error:false,
    zip_space_error:false,
    phone_space_error:true,
    zipcode_error:false});
  }
  else if(this.state.phonenumber != null && regexp.test(this.state.phonenumber) && this.state.phonenumber.replace(/ /g, '').length != 10) {
    this.setState({outletEndpoint_error:false,incomplete_error:false,
    phone_error:true,
      zip_space_error:false,
      phone_space_error:false,
    zipcode_error:false});
  }
  else if(this.state.zipcode != null && !regexp.test(this.state.zipcode)) {
    this.setState({
      outletEndpoint_error:false,
    zip_space_error:true,
    phone_space_error:false,
    incomplete_error:false,
    phone_error:false,
    zipcode_error:false});
  }
  else if(this.state.zipcode != null && regexp.test(this.state.zipcode) && this.state.zipcode.replace(/ /g, '').length != 5) {
    this.setState({outletEndpoint_error:false,incomplete_error:false,
    phone_error:false,
      zip_space_error:false,
      phone_space_error:false,
    zipcode_error:true});
  }
  else if(this.state.outletEndpoint != null && !urlRegexp.test(this.state.outletEndpoint)) {
    this.setState({outletEndpoint_error:true,incomplete_error:false,
    phone_error:false,
      zip_space_error:false,
      phone_space_error:false,
    zipcode_error:false});
  }
  else if(
      this.state.business_name.trim().length === 0 ||
      this.state.business_website.trim().length === 0 ||
      this.state.phonenumber.trim().length === 0 ||
      this.state.owner_name.trim().length === 0 ||
      this.state.business_address.trim().length === 0 ||
      this.state.business_tagline.trim().length === 0 ||
      this.state.city.trim().length === 0 ||
      this.state.zipcode.trim().length === 0 ||
      this.state.state.trim().length === 0 ||
      this.state.country.trim().length === 0
  ) {
    this.setState({
      outletEndpoint_error:false,
      incomplete_error:true,
    phone_error:false,
      zip_space_error:false,
      phone_space_error:false,
    zipcode_error:false});
  }

  else if(tab === 2 && (this.state.about === "" || this.state.about === null || this.state.about.trim().length === 0 ))
  {
    this.setState({outletEndpoint_error:false,incomplete_error:true,
    phone_error:false,
      zip_space_error:false,
      phone_space_error:false,
    zipcode_error:false});

  }

  else if(tab === 2 && (this.state.authorized === false || this.state.salesCheck === false)
  ) {
    this.setState({outletEndpoint_error:false,incomplete_error:false,
      phone_error:false,
        zip_space_error:false,
        phone_space_error:false,
      zipcode_error:false,
    checkbox_error:true});

  }

  else {
    this.setState({outletEndpoint_error:false,incomplete_error:false,
      phone_error:false,
      zipcode_error:false,
      zip_space_error:false,
      phone_space_error:false,
    current_tab:tab});
    console.log(localStorage.getItem('user'));

  }
};

OnHandleFieldChange = (field, event) => {
    const new_state = {};
    new_state[field] = event.target.value;
    this.setState(new_state);
};

OnHandleCategroyChange = (field, event) => {
    const new_state = {};
    new_state[field] = [event.target.value];
    this.setState(new_state);
};

handleSalesCheck = () => {
    this.setState({salesCheck:!this.state.salesCheck});
};
handleAuthCheck = () => {
    this.setState({authorized:!this.state.authorized});
};

handleSubmit = () => {

  if(this.state.business_name === null ||
    this.state.business_website === null ||
    this.state.phonenumber === null ||
    this.state.owner_name === null ||
    this.state.business_address === null ||
    this.state.business_tagline === null ||
    this.state.city === null ||
    this.state.zipcode === null ||
    this.state.state === null ||
    this.state.country === null ||
    this.state.about === null ||
    this.state.business_name === "" ||
      this.state.business_website === "" ||
      this.state.phonenumber === "" ||
      this.state.owner_name === "" ||
      this.state.business_address === "" ||
      this.state.business_tagline === "" ||
      this.state.city === "" ||
      this.state.zipcode === "" ||
      this.state.state === "" ||
      this.state.country === "" ||
      this.state.about === "" ||
      this.state.about.trim().length === 0

  ) {
    this.setState({outletEndpoint_error:false,incomplete_error:true,
    checkbox_error:false,
  phone_space_error:false,
  zip_space_error:false,
  phone_error:false,
  zipcode_error:false});

  }
  else if(this.state.authorized === false || this.state.salesCheck === false
  ) {
    this.setState({outletEndpoint_error:false,incomplete_error:false,
      phone_space_error:false,
      zip_space_error:false,
      phone_error:false,
      zipcode_error:false,
    checkbox_error:true});

  }
   else {
    this.setState({outletEndpoint_error:false,incomplete_error:false,
      checkbox_error:false,
      phone_space_error:false,
      zip_space_error:false,
      phone_error:false,
      zipcode_error:false,
    current_tab:2});
    console.log(localStorage.getItem('user'));

  }

  //this.props.goToStep(2)
};

handlePaymentClick = (payment_token) => {
  console.log('working stripe', this.props);

  const access_token = localStorage.getItem('access_token');
  const current_email = localStorage.getItem('user');
  const bearer = `Bearer ${access_token}`;
  const url1 =
    `${config.api_root}/api/v1/merchants/enrol/business_plans/en_US`;
  fetch(url1, {
    method: "POST",
    body: JSON.stringify({
      user_id: current_email,
      fields : {
           plan_id: "e84b7eff-2025-4921-974c-5dcf56060ba7",
           website: this.state.business_website,
           restaurantName: this.state.business_name,
           tagLine: this.state.business_tagline,
           about: this.state.about,
           ownerName: this.state.owner_name,
           email: current_email,
           mobileNumber: this.state.phonenumber,
           address: this.state.business_address,
           postalCode: this.state.zipcode,
           city: this.state.city,
           state: this.state.state,
           country: 254,
           termConditionAccepted: true,
           cardToken: payment_token,
           outletEndpoint:this.state.outletEndpoint,
           primary_food_categories:this.state.primary_food_categories,
           secondary_food_categories:this.state.secondary_food_categories
      }
      // fields : {
      //   restaurantName: this.state.business_name,
      //   tagLine: this.state.business_tagline,
      //   about: this.state.about,
      //   ownerName: this.state.owner_name,
      //   email: current_email,
      //   mobileNumber: this.state.phonenumber,
      //   address: this.state.business_address,
      //   postalCode: this.state.zipcode,
      //   city: this.state.city,
      //   state: this.state.state,
      //   country: 99,
      //   termConditionAccepted: true,
      //   outletEndpoint:this.state.outletEndpoint,
      //   primary_food_categories:this.state.primary_food_categories,
      //   secondary_food_categories:this.state.secondary_food_categories,
      // }
          }),
    headers: {
      "Authorization" : bearer,
      "Content-Type": "application/json"
    }
  }).then(response => response.json())
        .then(response => {
          console.log('regiserinfo',response )

          if(response.request_status) {
            this.setState({
              login_error:false,
              error_message:null
            })
            this.props.goToStep(4)
          }
          else if(response.object && response.object &&  response.object.error) {
              console.log('error',response )
              this.setState({
                login_error:true,
                error_message:response.object.error
              })
            }
            else if(response.paymentStatus === false) {
                console.log('error',response )
                this.setState({
                  login_error:true,
                  error_message:response.paymentFailedReason
                })
              }
            else {

              this.props.goToStep(4)
            }


        })
    .catch(error =>
      this.setState({
        login_error:true,
        message: "Something bad happened " + error,
        error_message:error
      })
    );
};



sendDataApp = (value_email,value_accesstoken) => {
  this.props.parentCallbackRoot(value_email,value_accesstoken);
}


  render() {
    console.log("email",this.state.user_email);
    console.log("access_token",this.state.access_token);

    const FormWithStripe = injectStripe(({ stripe }) => {
      return (
        <form
          onSubmit={evt => {
            evt.preventDefault();
            stripe.createToken().then(payload => {
              if (payload.token) {
                //  doSomething()
              //  alert("success");
              this.setState({
                card_error : false,
                card_error_message : ''
              })

              const access_token = localStorage.getItem('access_token');
              const current_email = localStorage.getItem('user');
              const bearer = `Bearer ${access_token}`;
              const url1 =
                `${config.api_root}/api/v1/merchants/enrol/business_plans/en_US`;
              fetch(url1, {
                method: "POST",
                body: JSON.stringify({
                  user_id: current_email,
                  fields : {
                       plan_id: "e84b7eff-2025-4921-974c-5dcf56060ba7",
                       website: this.state.business_website,
                       restaurantName: this.state.business_name,
                       tagLine: this.state.business_tagline,
                       about: this.state.about,
                       ownerName: this.state.owner_name,
                       email: current_email,
                       mobileNumber: this.state.phonenumber,
                       address: this.state.business_address,
                       postalCode: this.state.zipcode,
                       city: this.state.city,
                       state: this.state.state,
                       country: 254,
                       termConditionAccepted: true,
                       cardToken: payload.token.id,
                       outletEndpoint:this.state.outletEndpoint,
                       primary_food_categories:this.state.primary_food_categories,
                       secondary_food_categories:this.state.secondary_food_categories
                  }
                  // fields : {
                  //   restaurantName: this.state.business_name,
                  //   tagLine: this.state.business_tagline,
                  //   about: this.state.about,
                  //   ownerName: this.state.owner_name,
                  //   email: current_email,
                  //   mobileNumber: this.state.phonenumber,
                  //   address: this.state.business_address,
                  //   postalCode: this.state.zipcode,
                  //   city: this.state.city,
                  //   state: this.state.state,
                  //   country: 99,
                  //   termConditionAccepted: true,
                  //   outletEndpoint:this.state.outletEndpoint,
                  //   primary_food_categories:this.state.primary_food_categories,
                  //   secondary_food_categories:this.state.secondary_food_categories,
                  // }
                      }),
                headers: {
                  "Authorization" : bearer,
                  "Content-Type": "application/json"
                }
              }).then(response => response.json())
                    .then(response => {
                      console.log('regiserinfo',response )

                      if(response.request_status) {
                        this.setState({
                          login_error:false,
                          error_message:null
                        })
                        this.props.goToStep(4)
                      }
                      else if(response.object && response.object &&  response.object.error) {
                          console.log('error',response )
                          this.setState({
                            login_error:true,
                            error_message:response.object.error
                          })
                        }
                        else if(response.paymentStatus === false) {
                            console.log('error',response )
                            this.setState({
                              login_error:true,
                              error_message:response.paymentFailedReason
                            })
                          }
                        else {

                          this.props.goToStep(4)
                        }

                    })
                .catch(error =>
                  this.setState({
                    message: "Something bad happened " + error
                  })
                );

              }
              if (payload.error) {
                //alert(payload.error.message);
                this.setState({
                  card_error : true,
                  card_error_message : payload.error.message
                })
              }
            });
          }}
        >
          {this.state.card_error ? (<div className="stripe-error">{this.state.card_error_message}</div>):null}
          <CardSection />
          <p>
            <button
            //  onClick={()=>this.handlePaymentClick()}
              className="btn btn-next stripe-button"
            >
              Submit
            </button>
          </p>
        </form>
      );
    });


    return (
          <div className="App">
          <section className="sign-subscription-plan">
    <div className="container p-4">
      <div className="row sign-in-row">
        {/*--- Personal Details ----*/}
        <div className="rst-name-section py-4">
          <div className="container Checkout">
            <div className="row  mt-2 ">
              <div className="col-md-7 pl-2 pr-2">
                <div className="align-items-center list-card bg-white shadow p-4 cont-info-box brd-10">
                  <h4>Checkout</h4>
                  <div className="col-md-12 mt-4 r-personal-form-col ">
                  <div className="incomplete_error">{this.state.incomplete_error ? 'Please fill all the Information' : null}
                  {this.state.checkbox_error ? 'Please accept both checboxes listed below' : null}
                  {this.state.phone_error ? 'Phone number must be 10 digit number' : null}
                  {this.state.zipcode_error ? 'Zip code must be 5 digit code' : null}
                  {this.state.zip_space_error ? 'Zip code must be numeric (spaces & alphabets not allowed)' : null}
                  {this.state.outletEndpoint_error ? 'Perferred Ordering Platform should be a website (including http:// or https://)' : null}
                  {this.state.phone_space_error ? 'Phone Number must be numeric (spaces & alphabets not allowed)' : null}
                  {this.state.login_error && this.state.error_message != null ? this.state.error_message : null}</div>

                    <ul className="nav nav-pills select-role-user-rd-btn2">
                      <li className="tab-btn">
                        <a
                          data-toggle="pill"
                          className={this.state.current_tab === 0 ? "active" : ""}
                          onClick={()=>this.OnTabChange(0)}
                        >
                          Personal Details
                        </a>
                      </li>
                      <li className="tab-btn">
                        <a data-toggle="pill"
                        className={this.state.current_tab === 1 ? "active" : ""}
                        onClick={()=>this.OnTabChange(1)}>
                          Business Details
                        </a>
                      </li>
                      <li className="tab-btn">
                        <a data-toggle="pill"
                        className={this.state.current_tab === 2 ? "active" : ""}
                        onClick={()=>this.OnTabChange(2)}>
                          Confirm Information
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content mt-4 mb-2">
                      <div
                        id="PersonalDetails"
                        className={this.state.current_tab === 0 ? "tab-pane fade in active show" : "tab-pane fade in"}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <h3>Personal Details</h3>
                          </div>
                          {/*<div className="col-md-6 text-right">
                            <label htmlFor="signin">Signed In</label>
                            <input
                              type="checkbox"
                              id="signin"
                              name="signin"
                              defaultValue="signin"
                            />
                          </div>*/}
                          <div className="col-md-12">
                            <form>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="name">Business Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="text"
                                    placeholder="Business Name"
                                    onChange = {e => this.OnHandleFieldChange('business_name', e)}
                                    value={this.state.business_name}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="name">Business Website</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="text"
                                    placeholder="Business Website"
                                    onChange = {e => this.OnHandleFieldChange('business_website', e)}
                                    value={this.state.business_website}
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="number">Phone</label>
                                  <input
                                    type="phonenumber"
                                    className="form-control"
                                    id="number"
                                    placeholder="Phone"
                                    onChange = {e => this.OnHandleFieldChange('phonenumber', e)}
                                    value={this.state.phonenumber}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="name">Owner Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="text"
                                    placeholder="Owner Name"
                                    onChange = {e => this.OnHandleFieldChange('owner_name', e)}
                                    value={this.state.owner_name}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputAddress">
                                  Business Address
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputAddress"
                                  placeholder="Business Address"
                                  onChange = {e => this.OnHandleFieldChange('business_address', e)}
                                  value={this.state.business_address}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="inputAddress">
                                  Business Tagline
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputAddress"
                                  placeholder="Business Tagline"
                                  onChange = {e => this.OnHandleFieldChange('business_tagline', e)}
                                  value={this.state.business_tagline}
                                />
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="inputCity">City</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inputCity"
                                    onChange = {e => this.OnHandleFieldChange('city', e)}
                                    value={this.state.city}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="inputZip">Zip</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="inputZip"
                                    onChange = {e => this.OnHandleFieldChange('zipcode', e)}
                                    value={this.state.zipcode}
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="inputState">State</label>
                                  <input
                                    type="text"
                                    id="inputState"
                                    className="form-control"
                                    onChange = {e => this.OnHandleFieldChange('state', e)}
                                    value={this.state.state}
                                  />

                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="inputCountry">Country</label>

                                  <select id="inputCountry"
                                  className="form-control"
                                  onChange = {e => this.OnHandleFieldChange('country', e)}>
                                    <option value="">Select Country</option>
                                    <option value="254">United States</option>
                                  </select>

                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <label htmlFor="inputState">Perferred Ordering Platform</label>
                                  <input
                                    type="url"
                                    id="inputState"
                                    className="form-control"
                                    onChange = {e => this.OnHandleFieldChange('outletEndpoint', e)}
                                   value={this.state.outletEndpoint}
                                  />

                                </div>
                                </div>
                                <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="inputCountry">Primary Category</label>

                                  <select id="inputCountry"
                                  className="form-control"
                                  onChange = {e => this.OnHandleCategroyChange('primary_food_categories', e)}>
                                    <option value="">Select Category</option>
                                    <option value="Maxican">Maxican</option>
                                    <option value="Maxican">Indian</option>
                                    <option value="Maxican">Italian</option>
                                    <option value="Maxican">Chinese</option>
                                  </select>

                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="inputCountry">Secondary Category</label>

                                  <select id="inputCountry"
                                  className="form-control"
                                  onChange = {e => this.OnHandleCategroyChange('secondary_food_categories', e)}>
                                    <option value="">Select Category</option>
                                    <option value="Nepali">Nepali</option>
                                    <option value="Nepali">South Indian</option>

                                  </select>

                                </div>
                              </div>
                              <p>
                                <a
                                  onClick={()=>this.OnTabChange(1)}
                                  className="btn btn-next"
                                >
                                  Next Step
                                </a>
                              </p>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div id="BusinessDetails"
                      className={this.state.current_tab === 1 ? "tab-pane fade in active show" : "tab-pane fade in"}
                      >
                        <div className="row">
                          <h3>Business Details</h3>
                          <div className="form-row">
                            <label htmlFor="textarea">
                              {" "}
                              What Point of Sale (POS) do you use, if any?
                            </label>
                            <textarea
                              type="textarea"
                              className="form-control"
                              id="inputtext"
                              placeholder="Response"
                              defaultValue={""}
                              onChange = {e => this.OnHandleFieldChange('about', e)}
                              value={this.state.about}
                            />
                          </div>
                          <div className="cbox mt-4">
                            <input
                              type="checkbox"
                              id="authorized"
                              name="authorized"
                              checked={this.state.authorized}
                              onChange={this.handleAuthCheck}
                            />
                            <label htmlFor="authorized">
                              I attest I am authorized to make business decisions
                              on behalf of the <br />
                              company listed.
                            </label>
                          </div>
                          <div className="cbox mt-3">
                            <input
                              type="checkbox"
                              id="authorized"
                              name="authorized"
                              checked={this.state.salesCheck}
                              onChange={this.handleSalesCheck}

                            />
                            <label htmlFor="authorized">
                              I acknowledge that all sales are final and
                              non-refundable.
                            </label>
                            <p>
                              <a
                                //onClick={()=>this.OnTabChange(2)}
                                onClick={()=>this.handleSubmit()}
                                className="btn btn-next"
                              >
                                Proceed to Pay
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="ConfirmInformation"
                      className={this.state.current_tab === 2 ? "tab-pane fade in active show" : "tab-pane fade in"}>
                        <div className="row">

                          <h6 className="mt-3">Payment Information</h6>
                          <div className="payment-section">
                          <StripeScriptLoader
                             uniqueId="myUniqueId"
                             script="https://js.stripe.com/v3/"
                             loader="Loading..."
                           >
                             <StripeProvider apiKey="pk_test_sn4v71GtpdSuGyF3oVJLSj7I">
                               <Elements>
                                 <FormWithStripe />
                               </Elements>
                             </StripeProvider>
                           </StripeScriptLoader>



                           </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 pl-2 pr-2">
                <div className="align-items-center list-card bg-white shadow p-4 cont-info-box brd-10 ">
                  <h4>Cart</h4>
                  <div className="row select-role-user-rd-btn">
                    <div className="col-md-4">
                      <img
                        className="imglogo"
                        src="/img/dt-logo.png"
                      />
                    </div>
                    <div className="col-md-6">
                      <p>Checkout From</p>
                      <h3>Dining Tek</h3>
                    </div>
                  </div>

                  <div className="row mt-4 select-role-user-rd-btn-coupon">
                    <div className="col-md-8">
                      <h6>Order Summary</h6>
                      <p>Plan Name</p>
                    </div>
                    <div className="form-group col-md-4">
                      <p className="price">$1</p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-8">
                      <h6>Total</h6>
                    </div>
                    <div className="form-group col-md-4">
                      <p className="price">$1</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


          </div>
        );
  }
}


export default Second;
