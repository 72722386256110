import React,{ Component } from 'react';
import Header from '../components/Header';
import {config} from '../config';
import Cookies from 'js-cookie';


class Third extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_email : "",
        access_token : "",
        email: "",
        password: "",
        cpassword: "",
        passwordmatch:true,
        register_info:"",
        login_error:false,
        error_message:null,
        login_info : [],
        loginScreen: true,
        restlistScreen: false,
        restaurantsListData: []
    }
  }
  callbackFunction = (value1,value2) => {
    this.setState({
      user_email: value1,
      access_token : value2
    },()=>{
      this.sendDataApp(this.state.user_email,this.state.access_token);
    })
}

componentDidMount() {
  console.log("Component mounted");

  const user_email = localStorage.getItem('user');
  const access_token = localStorage.getItem('access_token');

  if (user_email != null && access_token != null) {
    const url3 =
`${config.api_root}/api/v1/merchants/enrol_config/en_US?user_id=${user_email}`;
fetch(url3, {
method: "GET",
headers: {
"Authorization": `Bearer ${access_token}`,
}
}).then(response => response.json())
.then(restresult => {
console.log('restresult', restresult.account_existed)
  this.setState({
    restaurantsListData: restresult.account_existed,
    loginScreen:false,
    restlistScreen:true
  })
 })
.catch(error =>
this.setState({
message: "Something bad happened " + error
})
);
  }
}

OnHandleFieldChange = (field, event) => {
    const new_state = {};
    new_state[field] = event.target.value;
    this.setState(new_state);
};

handleAddNew = () => {
    this.props.goToStep(2)
};

handleSubmit = () => {

    if(this.state.password != null){
      const logindata = {
        "email" : this.state.email,
        "password" :this.state.password
      }
      const url2 =
        `${config.api_root}/security/session/users/login`;
      fetch(url2, {
        method: "POST",
        body: JSON.stringify({
          user: logindata.email,                      //hjjcjcjcj@gmail.com
          password: logindata.password,                                 //1111
          bucket_id:""
        }),
        headers: {
          "Content-Type": "application/json",
          "device_id" : "21212121121212wqwqw",                //device id should be unique every time
          "key" : "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
          "secret" : "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
        }
      }).then(response => response.json())
            .then(login => {
              this.setState({
                login_info: login.object
              })
              if(login.request_status) {
                  this.setState({
                    login_Process : true
                  })

                console.log('correct login');
                localStorage.setItem('access_token', login.object.access_token);
                localStorage.setItem('user', this.state.email);
                const expirationDate = new Date();
                expirationDate.setMonth(expirationDate.getMonth() + 1);
                Cookies.set('RS_ACCESS_TOKEN', login.object.access_token, { domain: '.diningtek.com', expires: expirationDate });
                Cookies.set('USER', logindata.email, { domain: '.diningtek.com', expires: expirationDate });


                const url3 =
        `${config.api_root}/api/v1/merchants/enrol_config/en_US?user_id=${logindata.email}`;
      fetch(url3, {
        method: "GET",
        headers: {
		"Authorization": `Bearer ${login.object.access_token}`,
        }
      }).then(response => response.json())
            .then(restresult => {
				console.log('restresult', restresult.account_existed)

              this.setState({
                restaurantsListData: restresult.account_existed,
                loginScreen:false,
                restlistScreen:true
              })
              this.props.headerRefresh(login.object.access_token);
             })
        .catch(error =>
          this.setState({
            message: "Something bad happened " + error
          })
        );

                if (localStorage.getItem('user') != null && localStorage.getItem('access_token') != null){
                  //window.location.reload();
                  //this.props.goToStep(5)
                }

                // .then(() =>{
                //   console.log('Yes this is sending data');
                //   localStorage.setItem('access_token', this.state.login_info.access_token);
                //   localStorage.setItem('user', this.state.email);
                //   this.sendData(this.state.email,this.state.login_info.access_token);
                // })
              }
              else {
                this.setState({
                  error_message: "Invalid username or password"
                })
              }
            })
        .catch(error =>
          this.setState({
            message: "Something bad happened " + error
          })
        );
    }
  //this.props.goToStep(2)
};

sendDataApp = (value_email,value_accesstoken) => {
  this.props.parentCallbackRoot(value_email,value_accesstoken);
}
  render() {
    console.log("email",this.state.user_email);
    console.log("access_token",this.state.access_token);
    return (
          <div className="App">
          {this.state.loginScreen ? <section className="sign-in-second-page">
  <div className="container p-4">
    <div className="row sign-in-row">
    <div className="login-error">{this.state.login_info.error ? (<p>{this.state.login_info.error}</p>) : null}</div>
      <div className="col-md-12">
        <form>
          <div className="row">
            <div className="col-md-12">
              <input
                type="text"
                name=""
                placeholder="Email"
                className="login-fields"
                value = {this.state.email}
                onChange = {e => this.OnHandleFieldChange('email', e)}
              />
            </div>
            <div className="col-md-12">
              <input
                type="password"
                name=""
                placeholder="Password"
                className="login-fields"
                value = {this.state.password}
                onChange = {e => this.OnHandleFieldChange('password', e)}
              />
            </div>

            <div className="col-md-6">
              <label className="remember-me-label">
                <input type="checkbox" defaultValue="" />
                Remember me
              </label>
            </div>
            <div className="col-md-6 text-right">
              <p>
                Forgot your password? <a className="linkcolor" >Click Here</a>
              </p>
              <p>
                Don't have an account? <a className="linkcolor" onClick={()=>this.props.goToStep(1)}>Signup Here</a>
              </p>
            </div>
          </div>
        </form>
      </div>
      <div className="col-md-12 mt-4 mb-3 text-right">
        <a onClick={()=>this.handleSubmit()} className="widget-header mr-4 text-dark btn cst-btn">
          <div className="icon d-flex align-items-center">
            <span>Login</span>{" "}
            <i className="feather-arrow-right h6 ml-2 mb-0" />
          </div>
        </a>
      </div>
    </div>
  </div>
</section> : null }

{this.state.restlistScreen ? <section className="sign-subscription-plan">
<div className="container p-4">
<div className="row sign-in-row">
<div className="col-md-12 mt-3 mb-3">
<h1>Your Restaurants List</h1>
</div>
<div className="col-md-12 mt-3 mb-3 text-center">
<p>

</p>
</div>
<div className="col-md-12 mb-4">
<div className="select-role-user-rd-btn2">
<div className="row">
{this.state && this.state.restaurantsListData && this.state.restaurantsListData.length > 0 ? this.state.restaurantsListData.map((restaurant, index) => (
                    <div className="select-boxs" key={index}>
                      <label htmlFor="directorybtn">
                        <div className="sl-box">
                          <div className="restlogo"><h1>{restaurant.name.substring(0, 1)}</h1></div>
                          <h3>{restaurant.name}</h3>
                          {/* Render other restaurant data */}
                          <ul>
                            <li>{restaurant.tagline}</li>
                          </ul>
                          <p className="choose_plan-btn">
                            <a href="https://dashboard-pilot.diningtek.com">Manage</a>
                          </p>
                        </div>
                      </label>
                    </div>
                  )):null}


</div>
</div>
</div>
<div className="col-md-12 mt-4 mb-3 text-right">
<a
className="widget-header mr-4 text-dark btn cst-btn"
onClick={()=>this.handleAddNew()}
>
<div className="icon d-flex align-items-center">
  <span>Add New Restaurant</span>{" "}
  <i className="feather-arrow-right h6 ml-2 mb-0" />
</div>
</a>
</div>
</div>
</div>
</section> : null }

          </div>
        );
  }
}


export default Third;

export async function getServerSideProps(context) {
    console.log(context.query)
    const stripedata = await fetch(`${config.api_root}/service/status/${context.query.id}/WEB`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });
    const stripekeydata = await stripedata.json();


    //you can make DB queries using the data in context.query
    return {
        props: {
           title: stripekeydata //pass it to the page props
        }
    }
}
