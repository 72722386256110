import React,{useEffect, useMemo} from 'react'
import Header from "./Header";
import Footer from "./Footer";
import { useSelector,useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
function ThankYou(props){

  const config_data = useSelector(state =>state.Config)

  useEffect(() => {
  localStorage.removeItem('user');
  localStorage.removeItem('access_token');
  localStorage.removeItem('mid');
  Cookies.set('RS_ACCESS_TOKEN', null, { domain: '.diningtek.com', expires: 0 });
  Cookies.set('USER', null, { domain: '.diningtek.com', expires: 0 });
  Cookies.remove('RS_ACCESS_TOKEN');
  Cookies.remove('USER');
  if (localStorage.getItem('user') === null && localStorage.getItem('access_token') === null){
    window.location.href = '/'

  }
}, [])


  return(
    <>
                <Header configInfo={config_data && config_data.config && config_data.config.object ? config_data.config.object : null } />

                <div id="scrollmain" className="container" >
                  <div className="main1-wrapper">
                  <div className="row">
                  <div className ="col-md-12 Booking-confirmation">
                  <h2>Logging Out...</h2>
                  </div>
                  </div>
                  </div>
              </div>
              <div className="container top_row_sc">
                 <div className="main1-wrapper">
                <div className = "row">
                    <div className ="col-md-12">
                        <div className="row">
                          <div className="col-md-4"></div>
                          <div className="col-md-4"></div>
                          <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
</div>
</div>
                <Footer />
            </>
  )
}

export default ThankYou
