import React,{useEffect,useMemo,useState} from 'react'
import { useSelector,useDispatch, useStore } from 'react-redux';
import {config} from '../config';
import { Link } from "react-router-dom";
import { fetchRestaurantInformation } from '../Redux/RestaurantInformation/RestaurantInformationActions';
import FeatherIcon from 'feather-icons-react';

function Header(props){

  const restaurantInformation_data = useSelector(state =>state.RestaurantInformation)
  console.log('currentstep', props.currentStep)

  const menu_content =
  localStorage.getItem("access_token") === null ?
  (<div class="d-flex align-items-center justify-content-end">
     <a href="/Signup?loginsc=true" class="widget-header mr-4 text-dark btn  cst-btn">
        <div class="icon d-flex align-items-center">
          <FeatherIcon icon="user"/> <span>Log-in</span>
               </div>
     </a>
     <a href="/Signup" class="widget-header mr-4 text-dark btn cst-btn">

        <div class="icon d-flex align-items-center">
            <span>Sign-up</span> <FeatherIcon icon="arrow-right"/>
          </div>
     </a>
   </div> ) : (<div class="d-flex align-items-center justify-content-end">
      <a href="/logout" class="widget-header mr-4 text-dark btn cst-btn">

         <div class="icon d-flex align-items-center">
             <span>Logout</span> <FeatherIcon icon="arrow-right"/>
           </div>
      </a>
    </div> )

  return(

  <header className="header-area2 fixed-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar2 navbar-expand-lg navbar-light">
              <a className="navbar-brand navbar-brand2" href="/">
                  {/*<img src={restaurantInformation_data && restaurantInformation_data.restaurant_info && restaurantInformation_data.restaurant_info.object && restaurantInformation_data.restaurant_info.object.LOGO ? restaurantInformation_data.restaurant_info.object.LOGO : '/img/logo-loader.gif'} alt="logo" /> */}
                  <img src="/img/logo.png" alt="logo" />
                </a>
                <button className="hamburger hamburger--squeeze navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                  </ul>
                  <div className="main-menu-part2">
                  {menu_content}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
  )
}

export default Header
