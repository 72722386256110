import React,{ Component } from 'react';
import Header from '../components/Header';
import {config} from '../config';

class RestList extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_email : "",
        access_token : "",
        email: "",
        password: "",
        cpassword: "",
        passwordmatch:true,
        register_info:"",
        login_error:false,
        error_message:null,
        login_info : [],
        restaurantsList: []
    }
  }
  componentDidMount() {
    console.log("Component mounted");
    
    const user_email = localStorage.getItem('user');
    const access_token = localStorage.getItem('access_token');

    if (user_email != null && access_token != null) {
      const url3 =
    `${config.api_root}/api/v1/merchants/enrol_config/en_US?user_id=${user_email}`;
    fetch(url3, {
    method: "GET",
    headers: {
    "Authorization": `Bearer ${access_token}`,
    }
    }).then(response => response.json())
    .then(restresult => {
    console.log('restresult', restresult.account_existed)
    this.setState({
      restaurantsList: restresult.account_existed
    })
    })
    .catch(error =>
    this.setState({
    message: "Something bad happened " + error
    })
    )
    }
  }

  render() {
  //  console.log("email",this.state.user_email);
  //  console.log("access_token",this.state.access_token);
    return (
          <div className="App">
          <section className="sign-subscription-plan">
  <div className="container p-4">
    <div className="row sign-in-row">
      <div className="col-md-12 mt-3 mb-3">
        <h1>Your Restaurants List</h1>
          </div>
      <div className="col-md-12 mt-3 mb-3 text-center">
        <p>

        </p>
      </div>
      <div className="col-md-12 mb-4">
        <div className="select-role-user-rd-btn2">
          <div className="select-boxs">

            <label htmlFor="directorybtn">
              <div className="sl-box">
                <h3>DiningTek</h3>
                <ul>
                  <li>Lindas World Cafe</li>
                </ul>
                <span className="cost-txt"></span>
                <span className="cost-amunt"></span>
                <span className="cost-info-txt">

                </span>
                <p className="choose_plan-btn">
                  <a>Manage</a>
                </p>
              </div>
            </label>
          </div>
          <div className="select-boxs">

            <label htmlFor="directorybtn">
              <div className="sl-box">
                <h3>DiningTek</h3>
                <ul>
                  <li>Lindas World Cafe</li>
                </ul>
                <span className="cost-txt"></span>
                <span className="cost-amunt"></span>
                <span className="cost-info-txt">

                </span>
                <p className="choose_plan-btn">
                  <a>Manage</a>
                </p>
              </div>
            </label>
          </div>
          <div className="select-boxs">

            <label htmlFor="directorybtn">
              <div className="sl-box">
                <h3>DiningTek</h3>
                <ul>
                  <li>Lindas World Cafe</li>
                </ul>
                <span className="cost-txt"></span>
                <span className="cost-amunt"></span>
                <span className="cost-info-txt">

                </span>
                <p className="choose_plan-btn">
                  <a>Manage</a>
                </p>
              </div>
            </label>
          </div>
          <div className="select-boxs">

            <label htmlFor="directorybtn">
              <div className="sl-box">
                <h3>DiningTek</h3>
                <ul>
                  <li>Lindas World Cafe</li>
                </ul>
                <span className="cost-txt"></span>
                <span className="cost-amunt"></span>
                <span className="cost-info-txt">

                </span>
                <p className="choose_plan-btn">
                  <a>Manage</a>
                </p>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-4 mb-3 text-right">
        <a
          className="widget-header mr-4 text-dark btn cst-btn"
        >
          <div className="icon d-flex align-items-center">
            <span>Add New Restaurant</span>{" "}
            <i className="feather-arrow-right h6 ml-2 mb-0" />
          </div>
        </a>
      </div>
    </div>
  </div>
</section>


          </div>
        );
  }
}


export default RestList;
