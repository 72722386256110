import React,{ Component } from 'react';
import Header from '../components/Header';
import StepWizard from "react-step-wizard";
import First from "./first"
import Second from "./Second"
import Third from "./Third"
import Success from "./Success"
import RestList from "./RestList"
import SignUpHeader from "./signupHeader"

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_email : "",
        access_token : "",
        headerRefresh:null
    }
  }
  callbackFunction = (value1,value2) => {
    this.setState({
      user_email: value1,
      access_token : value2
    },()=>{
      this.sendDataApp(this.state.user_email,this.state.access_token);
    })
}

updateHeaderState = (values)=>{
    this.setState(values);
  }

sendDataApp = (value_email,value_accesstoken) => {
  this.props.parentCallbackRoot(value_email,value_accesstoken);
}

headerRefresh = (value_accesstoken) => {
  this.setState({
    headerRefresh:value_accesstoken
  })
}

  render() {
    console.log("email",this.state.user_email);
    console.log("access_token",this.state.access_token);
    return (
          <div className="App Signup">

            <Header headerRefresh={this.state.headerRefresh}/>
            <StepWizard>
            <First />
            <Second />
            <Third />
            <Success />
            <RestList />

          </StepWizard>

          </div>
        );
  }
}


export default App;
