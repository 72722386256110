import React,{ Component } from 'react';
import Header from '../components/Header';

class First extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_email : "",
        access_token : ""
    }
  }
  callbackFunction = (value1,value2) => {
    this.setState({
      user_email: value1,
      access_token : value2
    },()=>{
      this.sendDataApp(this.state.user_email,this.state.access_token);
    })
}

sendDataApp = (value_email,value_accesstoken) => {
  this.props.parentCallbackRoot(value_email,value_accesstoken);
}
  render() {
    console.log("email",this.state.user_email);
    console.log("access_token",this.state.access_token);
    return (
          <div className="App">
          <section className="sign-subscription-plan">
    <div className="container p-4">
      <div className="row sign-in-row">
        <div className="col-md-3" />
        <div className="col-md-6">
          <div className="align-items-center list-card bg-white shadow p-4 cont-info-box brd-10 success ">
            <div className="rst-name-section py-4">
              <div className="container Checkout">
                <h2>Success!</h2>
                <p>Thank you for your purchase. We've received your request and we will reach back to you soon!</p>
                <img src="/img/sucess.png" />
                <a href="/Signup">View Your Restraunts</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3" />
      </div>
    </div>
  </section>


          </div>
        );
  }
}


export default First;
