import React, { useEffect, useState } from "react";
import StarRatingComponent from "react-star-rating-component";
import { Link } from "react-router-dom";
import { config } from "../config";
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import FeatherIcon from "feather-icons-react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import offer from '../assets/images/offer.png.jpg.png';

const settings = {
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide:0,
  adaptiveHeight:true,
  centerMode:false,
  responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};


const AllRestaurants = () => {
  const [restaurantData, setRestaurantData] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [merchantToken, setMerchantToken] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [totalRestaurant, setTotalRestaurant] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [seeAll, setSeeAll] = useState(0);
  const [newRestData, setNewRestData] = useState(null);

  useEffect(() => {
    const urlMerchantToken = `${config.api_root}/security/session/merchants?Key=diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq&Secret=ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx&device_id=21212121121212wqwqw`;
    fetch(urlMerchantToken, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Key: "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
        Secret:
          "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx",
      },
    })
      .then((response) => response.json())
      .then((merchant) => {
        if(merchant.object && merchant.object.access_token) {
        setMerchantToken(merchant.object.access_token)

          const url = `${config.api_base}/merchants/shop/search_by_categories?distance_min=0&type=RESTAURANTS,MOBILE_RESTAURANTS,OUTLET,DIRECTORY&distance_max=100&page=0&size=30&search=REGIONAL&lt=40.016870&ln=-105.0799494&serviceType=NO&opened_only=false`;
          const bearer = "Bearer" + merchant.object.access_token;
          fetch(url, {
            method: "GET",
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((responseData) => {
              console.log("search results", responseData);
              setRestaurantData(responseData.data);
              setResponseData(responseData);
              setTotalRestaurant(responseData.total);
              setNewRestData(responseData);
            })
            .catch((error) =>
              console.error("Something bad happened: ", error)
            );
      }
      })
      .catch((error) =>
        console.error("Something bad happened: ", error)
      );
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSeeAllClick = (key) => {
    if(seeAll !== 0) {
      setSeeAll(0);
    } else {
      setSeeAll(key);
    }

  };

  const restList = newRestData;

  const loadingRestaurantsNew = (
    <div className="row loading-row">
      {Array.apply(null, Array(9)).map(function (item, i) {
        return (
          <div className="col-lg-4 col-md-6" key={i}>
            <div className="loader-img">
              <img
                src="/img/menu-item-loader.gif"
                alt="images not found"
                className="img-fluid"
              />
            </div>
          </div>
        );
      }, this)}
    </div>
  );

  const newRestaurant3 =
    newRestData != undefined && newRestData != null
      ? Object.keys(newRestData).map(function (key, item) {
          if (
            restList[key] &&
            restList[key].total &&
            restList[key].total > 0
          ) {
            return (
              <div className= {`slider-rest ${seeAll === key
                  ? "activeSlide"
                  : "inactiveSlide"
              }`}  key={key}>
                <div
                  className="py-3 title d-flex align-items-center">
                  <h5 className="m-0">{key}</h5>
                  <a
                    className="font-weight-bold ml-auto seeAllbtn"
                    onClick={(e) => handleSeeAllClick(key)}
                  >
                    {seeAll != 0 ? 'See Less' : 'See All'} <FeatherIcon icon="chevron-right" />
                  </a>
                </div>
                {seeAll !== 0 ?
                  (<div>
                    {restList[key].data.map((item, index) => {
                      const restaurantUrl = `restaurant/${item.merchant_id}`;
                      return (
                        <div className="offer-slider rounded" key={index}>
                        <div className="osahan-slider-item col-md-3">
                          <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div className="list-card overflow-hidden consider-info-box mt-2">
                              <div className="row displaycont">
                                <div className="col-md-3">
                                  <div className="brnd-fav-icon">
                                    {item.name.slice(0, 1)}
                                  </div>
                                </div>
                                <div className="col-md-7 pl-0">
                                  <p className="mb-1 font-weight-bold" style={{fontSize:"14px"}}>
                                    {item.name}
                                  </p>
                                  <p className="small mb-0">
                                    {item.tagLine}
                                  </p>
                                </div>
                                {item.specialDeals || item.specialEvents ? 
                               (
                                <div className="">
                             <img src={offer} height={45} width={45}/>
                             </div>
                                  )   
                               :<></>}
                              </div>
                            </div>
                            <div className="p-2 position-relative"></div>
                            {/* add deals and events  */}
                         
                                {/* add deals and events  */}
                            <div className="list-card-image">
                              <a href={restaurantUrl}>
                                <img
                                  alt=""
                                  src={item.business.BANNER}
                                  className="img-fluid item-img"
                                />
                              </a>
                            </div>
                            <div className="button h6 m-3">
                              <a href={restaurantUrl}>
                                <button className="order bg-warning">
                                  Order
                                </button>
                              </a>
                              <button className="reserve hideitnow">
                                Reserve
                              </button>
                              <div className="d-inline-block h6">
                                {/* <FeatherIcon
                                  icon="heart"
                                  size="18"
                                  className="h3 mr-2 mb-0"
                                />
                                <FeatherIcon
                                  icon="share-2"
                                  size="18"
                                  className="h3 mr-2 mb-0"
                                /> */}
                                  <div style={{paddingBottom:"10px",margin:"0px 10px"}}>
                              {/* {item.specialDeals || item.specialEvents ? 
                               (
                                <div className="">
                             <img src={offer} height={35} width={40}/>
                             </div>
                                  )   
                               :<></>} */}
                               
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      );
                    })}
                  </div>)
                  :
                <Slider {...settings}>
                  {restList[key].data.map((item, index) => {
                    const restaurantUrl = `restaurant/${item.merchant_id}`;
                    return (
                      <div className="offer-slider rounded" key={index}>
                        <div className="osahan-slider-item col-md-3">
                          <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div className="list-card overflow-hidden consider-info-box mt-2">
                              <div className="row displaycont">
                                <div className="col-md-3">
                                  <div className="brnd-fav-icon">
                                    {item.name.slice(0, 1)}
                                  </div>
                                </div>
                                <div className="col-md-7 pl-0">
                                  <p className="mb-1 font-weight-bold" style={{fontSize:"14px"}}>
                                    {item.name}
                                  </p>
                                  <p className="small mb-0">
                                    {item.tagLine}
                                  </p>
                                </div>
                                {item.specialDeals || item.specialEvents ? 
                               (
                                <div className="">
                             <img src={offer} height={45} width={45}/>
                             </div>
                                  )   
                               :<></>}
                              </div>
                            </div>
                            <div className="p-2 position-relative"></div>
                            {/* add deals and events  */}
                         
                                {/* add deals and events  */}
                            <div className="list-card-image">
                              <a href={restaurantUrl}>
                                <img
                                  alt=""
                                  src={item.business.BANNER}
                                  className="img-fluid item-img"
                                />
                              </a>
                            </div>
                            <div className="button h6 m-3">
                              <a href={restaurantUrl}>
                                <button className="order bg-warning">
                                  Order
                                </button>
                              </a>
                              <button className="reserve hideitnow">
                                Reserve
                              </button>
                              <div className="d-inline-block h6">
                                {/* <FeatherIcon
                                  icon="heart"
                                  size="18"
                                  className="h3 mr-2 mb-0"
                                />
                                <FeatherIcon
                                  icon="share-2"
                                  size="18"
                                  className="h3 mr-2 mb-0"
                                /> */}
                                  <div style={{paddingBottom:"10px",margin:"0px 10px"}}>
                              {/* {item.specialDeals || item.specialEvents ? 
                               (
                                <div className="">
                             <img src={offer} height={35} width={40}/>
                             </div>
                                  )   
                               :<></>} */}
                               
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>}
              </div>
            );
          }
        })
      : null;


  return (
    <section id="near-retaurent">
      <div className="container" id={seeAll !== 0 ? "seeAllMode" : ""}>
        {restaurantData !== undefined ? (
          restaurantData.length > 0 ? null : (
            loadingRestaurantsNew
          )
        ) : null}

        {newRestData ? newRestaurant3 : null}
      </div>
    </section>
  );
};

export default AllRestaurants;
