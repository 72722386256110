import React,{ Component } from 'react';
import Header from '../components/Header';
import {config} from '../config';

class First extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_email : "",
        access_token : "",
        email: "",
        password: "",
        cpassword: "",
        passwordmatch:true,
        register_info:"",
        login_error:false,
        error_message:null,
        processing:false,
        field_valid:true
    }
  }
  callbackFunction = (value1,value2) => {
    this.setState({
      user_email: value1,
      access_token : value2
    },()=>{
      this.sendDataApp(this.state.user_email,this.state.access_token);
    })
}

componentDidMount() {
  const user_email = localStorage.getItem('user');
  const access_token = localStorage.getItem('access_token');

  if (user_email != null && access_token != null) {
    this.props.goToStep(3);
  }

  if (window.location.href.includes('loginsc')) {
    this.props.goToStep(3);
  }
}

OnHandleFieldChange = (field, event) => {
    const new_state = {};
    new_state[field] = event.target.value;
    this.setState(new_state);
};
handleSubmit = (event) => {
  this.setState({
    processing : true
  })
  console.log('email', this.state.email);
  console.log('password', this.state.password);
  if(this.state.email === '' || this.state.password === '' || this.state.cpassword === '') {
    this.setState({
      field_valid: false,
      processing : false,
      passwordmatch :true,
      login_error:false,
      error_message:null,
    })
  }
  else if(this.state.password != this.state.cpassword) {
    this.setState({
      passwordmatch :false,
      processing : false,
      field_valid: true,
      login_error:false,
      error_message:null,
    })
  } else {
    this.setState({
      passwordmatch :true,
      processing : false,
      field_valid: true,
      login_error:false,
      error_message:null,
    })

    const data = {
      "email" : this.state.email,
      "password" :this.state.password
    }
    //console.log("data first name",data.first_name);
    const url1 =
      `${config.api_root}/security/session/users/register`;
    fetch(url1, {
      method: "POST",
      body: JSON.stringify({
              fields: {
                  email: data.email,
                  first_name: "DiningTek",
                  middle_name : "",
                  last_name : "DiningTek",
                  password: data.password,
                  registered_as_seller: true
                  },
                  form_id: "",
                  }),
      headers: {
        "Content-Type": "application/json",
        Key: "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
        Secret:
          "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
      }
    }).then(response => response.json())
          .then(response => {
            console.log('regiserinfo',response )
            if(response.request_status) {
              this.setState({
                login_error:false,
                error_message:null
              })
              if(this.state.password != null){
                const logindata = {
                  "email" : this.state.email,
                  "password" :this.state.password
                }
                const url2 =
                  `${config.api_root}/security/session/users/login`;
                fetch(url2, {
                  method: "POST",
                  body: JSON.stringify({
                    user: logindata.email,
                    password: logindata.password,
                    bucket_id:""
                  }),
                  headers: {
                    "Content-Type": "application/json",
                    "device_id" : "21212121121212wqwqw",
                    "key" : "diningtek_yDrlcdneFmPYyJOUoPUDSgGqLTdUjdFaUzFRwBlQfqCijWMkxq",
                    "secret" : "ZDBXXVXZXvJMZIdVItQmutURzSkfptKHkVyzaGKmlsuWpZKgxDbQtFdFImTTTTHVFViXAqfeIWStdDvqmEyzoujsSuEHZeOnSAsEseMvYfsYpSAOuEDwnAXkAadsImVWDBmAFDwtriHHFjtfyurHlQSvhdGfrmGPyYebRLedrUJvdIQzxScVPIRwDfFvVuPIHHPyBhvx"
                  }
                }).then(response => response.json())
                      .then(login => {
                        this.setState({
                          login_info: login.object
                        })
                        if(login.request_status) {
                            this.setState({
                              login_Process : true
                            })
                          console.log('correct login');
                          localStorage.setItem('access_token', login.object.access_token);
                          localStorage.setItem('user', this.state.email);

                          if (localStorage.getItem('user') != null && localStorage.getItem('access_token') != null){

                            this.props.goToStep(2)

                              const url3 =
                                `${config.api_root}/api/v1/merchants/enrol_config/en_US?user_id=${this.state.email}`;
                              fetch(url3, {
                                method: "GET",
                                headers: {
                                  "Content-Type": "application/json",
                                  "Authorization" : `bearer ${localStorage.getItem('access_token')}`,
                                }
                              }).then(response => response.json())
                                    .then(login => {
                                      console.log('logininfo', login)
                                      this.setState({
                                        login_info: login.object
                                      })
                                      if(login.request_status) {
                                          this.setState({
                                            login_Process : true
                                          })
                                        console.log('correct login');
                                        localStorage.setItem('access_token', login.object.access_token);
                                        localStorage.setItem('user', this.state.email);




                                      }
                                      else {
                                        this.setState({
                                          error_message: "Config error",
                                          processing : false,
                                          field_valid: true
                                        })
                                      }
                                    })
                                .catch(error =>
                                  this.setState({
                                    message: "Something bad happened " + error,
                                    processing : false,
                                    field_valid: true
                                  })
                                );

                          }


                        }
                        else {
                          this.setState({
                            error_message: "Invalid username or password",
                            processing : false,
                            field_valid: true
                          })
                        }
                      })
                  .catch(error =>
                    this.setState({
                      message: "Something bad happened " + error,
                      processing : false,
                      field_valid: true
                    })
                  );
              }
            }
            else {
              if(response.object && response.object.error) {
                this.setState({
                  login_error:true,
                  error_message:response.object.error,
                  processing : false,
                  field_valid: true
                })
              }
            }

          })
      .catch(error =>
        this.setState({
          message: "Something bad happened " + error
        })
      );

}
  //this.props.goToStep(2)
};

sendDataApp = (value_email,value_accesstoken) => {
  this.props.parentCallbackRoot(value_email,value_accesstoken);
}
  render() {
    console.log("email",this.state.user_email);
    console.log("access_token",this.state.access_token);
    return (
          <div className="App">
          <section className="sign-in-second-page">
  <div className="container p-4">
    <div className="row sign-in-row">
    <div className="login-error"> {this.state.login_error && this.state.error_message != null ? this.state.error_message : null}<br/>
    {this.state.field_valid != true ? "Please fill all fields" : null} <br/>
    {this.state.passwordmatch != true ? "Password & Confirm Password does not match" : null}</div>
      <div className="col-md-12">
        <form>
          <div className="row">
            <div className="col-md-12">
              <input
                type="text"
                name=""
                placeholder="Email"
                className="login-fields"
                value = {this.state.email}
                onChange = {e => this.OnHandleFieldChange('email', e)}
              />
            </div>
            <div className="col-md-12">
              <input
                type="password"
                name=""
                placeholder="Password"
                className="login-fields"
                value = {this.state.password}
                onChange = {e => this.OnHandleFieldChange('password', e)}
              />
            </div>
            <div className="col-md-12">
              <input
                type="password"
                name=""
                placeholder="Confirm Password"
                className="login-fields"
                value = {this.state.cpassword}
                onChange = {e => this.OnHandleFieldChange('cpassword', e)}
              />
            </div>
            <div className="col-md-6">
              <label className="remember-me-label">
                <input type="checkbox" defaultValue="" />
                Remember me
              </label>
            </div>
            <div className="col-md-6 text-right">
              <p>
                Forgot your password? <a className="linkcolor" >Click Here</a>
              </p>
              <p>
                Already have an account? <a className="linkcolor" onClick={()=>this.props.goToStep(3)}>Login Here</a>
              </p>
            </div>
          </div>
        </form>
      </div>
      <div className="col-md-12 mt-4 mb-3 text-right">
        <a onClick={()=>this.handleSubmit()} className="widget-header mr-4 text-dark btn cst-btn">
          <div className="icon d-flex align-items-center">
            <span>{this.state.processing ? 'Processing': 'Sign-up'}</span>{" "}
            <i className="feather-arrow-right h6 ml-2 mb-0" />
          </div>
        </a>
      </div>
    </div>
  </div>
</section>

          </div>
        );
  }
}


export default First;
